const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: false,
  };
  
  const defaultReducer = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return { ...state, ...rest };
      case 'SET_CURRENT_TENANT':
        return { ...state, isCurrentTenantSave: rest.isCurrentTenantSave };
      case 'CHANGED_DATE':
        return { ...state, date: rest.date };
      default:
        return state;
    }
  };
  
  export default defaultReducer;
  