import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers, createStore } from 'redux'
import appConfig from './appConfig'
import defaultReducer from './reducers/DefaultReducer';


// const initialState = {
//   sidebarShow: true,
// }

const baseUrl = appConfig.publicUrl;
const history = createBrowserHistory({ basename: baseUrl});

const reducers = combineReducers({
  router: connectRouter(history),
  appContent: defaultReducer,
});

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

//const store = createStore(changeState)
const store = configureStore({
  reducer: reducers,
  middleware: m => m().concat(routerMiddleware(history)),
  devTools: appConfig.environment !== 'Production'
});

export { store, history }
